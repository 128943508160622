// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";

const UserHeader = (props) => {
    let tname = props.tickerName === '' ? localStorage.getItem('corporate_tickerName') : props.tickerName
    const imageUrl = `https://s3.ap-east-1.amazonaws.com/cdn.capex.mn/assets/corporate-banners/card-banner/${tname}.png`;
    return (
        <>
            <div
                className="header pb-8  d-flex align-items-center"
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundPosition: "center",
                    backgroundSize: 'cover',
                    backgroundColor: 'black',
                    width: `${window.innerWidth}`,
                    height: 'fit-content',
                    marginBottom: '5vh',
                    paddingTop: '25%'
                }}
            >
                {/* Mask */}
                <span className="bg-gradient-default opacity-8"/>
                {/* Header container */}
                {/*<Container className="d-flex align-items-center" fluid>*/}
                {/*  <Row>*/}
                {/*    <Col lg="7" md="10">*/}
                {/*      <h1 className="display-2 text-white">Hello Jesse</h1>*/}
                {/*      <p className="text-white mt-0 mb-5">*/}
                {/*        This is your profile page. You can see the progress you've made*/}
                {/*        with your work and manage your projects or assigned tasks*/}
                {/*      </p>*/}
                {/*      /!*<Button*!/*/}
                {/*      /!*  color="info"*!/*/}
                {/*      /!*  href="#pablo"*!/*/}
                {/*      /!*  onClick={(e) => e.preventDefault()}*!/*/}
                {/*      /!*>*!/*/}
                {/*      /!*  Edit profile*!/*/}
                {/*      /!*</Button>*!/*/}
                {/*    </Col>*/}
                {/*  </Row>*/}
                {/*</Container>*/}
            </div>
        </>
    );
};

export default UserHeader;
