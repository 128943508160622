// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";
import {useNavigate} from 'react-router-dom';

// core components
import Header from "components/Headers/Header.js";
import React, {useState} from "react";
import BffService from "../api";
import {Editor} from "@tinymce/tinymce-react";
import AddUser from "./AddUser";
import addUser from "./AddUser";
import {now} from "moment";

const Glossary = (props) => {
    const [listData, setListData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [descriptionMn, setDescriptionMn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [active, setActive] = useState(false);

    React.useEffect(() => {
        loadGlossary()
    }, [props.deps]);
    const saveGlossary = async () => {
        let data = {
            ID: id,
            DescriptionMn: descriptionMn,
            DescriptionEn: descriptionEn,
            Name: name,
            Active: active,
        }
        let result = await BffService.glossarySaveOrEdit(data)
        setIsOpen(false)
        loadGlossary()
    }
    const loadGlossary = async () => {
        setLoading(true)
        let zodiacData = await BffService.getGlossary()
        setListData(zodiacData.data.result)
        setFilteredData(zodiacData.data.result)
    }
    const toggleSwitch = () => {
        setActive(active !== true);
    };
    const searchData = (e) => {
        let searchText = e.target.value;
        setFilteredData(listData.filter(item => {
            if(
                item.name.toUpperCase().includes(searchText.toUpperCase()) ||
                item.descriptionMn.toUpperCase().includes(searchText.toUpperCase()) ||
                item.descriptionEn.toUpperCase().includes(searchText.toUpperCase())
            ){return item}
        }));
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const openModal = (e) => {
        if (e === null) {
            setId(null)
            setName('')
            setDescriptionMn('')
            setDescriptionEn('')
            setActive(true)
        } else {
            setId(e.id)
            setName(e.name)
            setDescriptionMn(e.descriptionMn)
            setDescriptionEn(e.descriptionEn)
            setActive(e.active)
        }
        setIsOpen(true);
    };
    return (
        <><Modal isOpen={isOpen} size="lg">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Үгсийн сан</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4" size="sm" color="warning" type="button"
                                    onClick={handleClose}>x</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Нэр</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                    id="cid"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="corp">Англи тайлбар</label>

                                <Input
                                    className="form-control-alternative"
                                    defaultValue={descriptionEn}
                                    onChange={(e) => setDescriptionEn(e.target.value)}
                                    id="cid"
                                    type="textarea"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="corp">Монгол тайлбар</label>

                                <Input
                                    className="form-control-alternative"
                                    defaultValue={descriptionMn}
                                    onChange={(e) => setDescriptionMn(e.target.value)}
                                    id="cid"
                                    type="textarea"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Button className="my-4"
                                color={active ? 'success' : 'warning'}
                                size="sm"
                                type="checkbox"
                                onClick={toggleSwitch}>
                            {active ? 'Идэвхитэй' : 'Идэвхигүй'}
                        </Button>
                        <Button className="my-4"
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={saveGlossary}>
                            Хадгалах
                        </Button>
                    </Row>
                </CardBody>
            </Modal>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Үгсийн сан</h3>
                                    <div>
                                        <Button color="primary" size="sm" type="button"
                                                onClick={(e) => openModal(null)}>Бүртгэх</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody style={{width: '100%'}}>
                                <Input className="form-control-alternative"  placeholder={"Хайх утга оруулна уу"} defaultValue={''} onKeyUp={(e) => searchData(e)} type="text"/>
                                <br/>
                                <Table className="align-items-center data" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Нэр</th>
                                        <th scope="col">Тайлбар MN</th>
                                        <th scope="col">Тайлбар EN</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredData && filteredData.length > 0 && filteredData.map(element => {
                                        return (
                                            <tr key={element.id}>
                                                <td>{element.name}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.descriptionMn}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.descriptionEn}</td>
                                                <td><Button className="my-4" color="primary" size="sm"
                                                            type="button"
                                                            onClick={(e) => openModal(element)}>Харах</Button></td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default Glossary;
