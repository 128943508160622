
import Index from "views/Index.js";
import ProfileCorporate from "./views/ProfileCorporate.js";
import Maps from "./views/Maps.js";
import Register from "./views/Register.js";
import Login from "./views/Login.js";
import Tables from "./views/Tables.js";
import Icons from "./views/Icons.js";
import CorporateList from "./views/CorporateList";
import NewsList from "./views/News";
import UserCorporate from "./views/UserCorporate";
import ZodiacList from "./views/ZodiacList";
import Glossary from "./views/Glossary";
import Lesson from "./views/Lesson";
import Quiz from "./views/Quiz";
import FailedDeposit from "./views/FailedDeposit";
import CardTransaction from "./views/CardTransaction";
import CardSettlement from "./views/CardSettlement";
import InvestPackage from "./views/InvestPackage";
import InvestPackageRequests from "./views/InvestPackageRequests";

var routes = [
  {
    path: "/corporate",
    name: "Байгууллага",
    icon: "ni ni-tv-2 text-primary",
    component: <CorporateList />,
    layout: "/admin",
  },
  {
    path: "/NewsList",
    name: "Мэдээ",
    icon: "ni ni-tv-2 text-primary",
    component: <NewsList />,
    layout: "/admin",
  },
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Index />,
  //   layout: "/admin",
  // },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
  },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: <Maps />,
  //   layout: "/admin",
  // },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <ProfileCorporate />,
    layout: "/admin",
  },
  {
    path: "/userList",
    name: "User",
    icon: "ni ni-single-02 text-yellow",
    component: <UserCorporate />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
  },
  {
    path: "/FailedDeposit",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <FailedDeposit />,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <CardTransaction />,
    layout: "/admin",
  },
  {
    path: "/settlement",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <CardSettlement />,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <FailedDeposit />,
    layout: "/admin",
  },
  {
    path: "/lesson",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Lesson />,
    layout: "/admin",
  },
  {
    path: "/quiz",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Quiz />,
    layout: "/admin",
  },
  {
    path: "/zodiac",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <ZodiacList />,
    layout: "/admin",
  },
  {
    path: "/glossary",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Glossary />,
    layout: "/admin",
  },
  {
    path: "/invest",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <InvestPackage />,
    layout: "/admin",
  },
  {
    path: "/invest/list",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <InvestPackageRequests/>,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <Register />,
  //   layout: "/auth",
  // },
];
export default routes;
