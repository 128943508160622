// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";
import {useNavigate} from 'react-router-dom';

// core components
import Header from "components/Headers/Header.js";
import React, {useState} from "react";
import BffService from "../api";
import {Editor} from "@tinymce/tinymce-react";
import AddUser from "./AddUser";
import addUser from "./AddUser";
import {now} from "moment";
import {v4 as uuidv4} from "uuid";

const InvestPackage = (props) => {


    const [amountMax , setAmountMax] = useState(null)
    const [amountMin , setAmountMin] = useState(null)
    const [asset , setAsset] = useState(null)
    const [createdDate , setCreatedDate] = useState(null)
    const [id , setId] = useState(null)
    const [info , setInfo] = useState(null)
    const [period , setperiod] = useState([])
    const [status , setStatus] = useState(true)


    React.useEffect(() => {
        fetchProduct()
    }, [props.deps]);

    const fetchProduct = async () => {
        let zodiacData = await BffService.getProductData()
        setAmountMax(zodiacData.data.result.amountMax)
        setAmountMin(zodiacData.data.result.amountMin)
        setAsset(zodiacData.data.result.asset)
        setCreatedDate(zodiacData.data.result.createdDate)
        setId(zodiacData.data.result.id)
        setInfo(zodiacData.data.result.info)
        setperiod(JSON.parse(zodiacData.data.result.period))
        setStatus(true)

    }
    const removeFromList = async (e, index) => {
        const updatedList = period.filter((question) => question.id !== e.id);
        setperiod(updatedList);
    };
    const saveData = async () => {
        
        let data = {
            amountMax : amountMax,
            amountMin : amountMin,
            asset : asset,
            createdDate : createdDate,
            id : id,
            info : info,
            period : JSON.stringify(period),
            status : true
        }
        await BffService.editProductData(data)
        await fetchProduct()
    }
    
    const toggleSwitch = () => {
        setStatus(status !== true);
    };
    return (
        <>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Capex academy</h3>

                                </div>
                            </CardHeader>
                            <CardBody style={{width: '100%'}}>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="cid">ASSET</label>
                                            <Input className="form-control-alternative" defaultValue={asset} onChange={(e) => setAsset(e.target.value)} id="cid" type="text"/>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="cid">Info</label>
                                            <Input disabled={true} className="form-control-alternative" defaultValue={info} onChange={(e) => setInfo(e.target.value)} id="cid" type="text"/>
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="cid">amountMin</label>
                                            <Input className="form-control-alternative" defaultValue={amountMin} onChange={(e) => setAmountMin(e.target.value)} id="cid" type="text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="cid">amountMax</label>
                                            <Input className="form-control-alternative" defaultValue={amountMax} onChange={(e) => setAmountMax(e.target.value)} id="cid" type="number"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button className="my-4" color="primary" size="sm" type="button" onClick={event => {setperiod([...period, {id : uuidv4(),month: 1, fee: 1,termsConditionUrl: ''}])}}>Нэмэх</Button>
                                <div style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
                                {period.map((item, index) => (
                                    <>
                                        <Row>
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="cid">Сар</label>
                                                <Input className="form-control-alternative" defaultValue={item.month} onChange={(e) => {item.month = e.target.value}} type="number"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="cid">Хүү</label>
                                                <Input className="form-control-alternative" defaultValue={item.fee}
                                                       onChange={(e) => {item.fee = e.target.value}} type="number"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="cid">PDF</label>
                                                <Input className="form-control-alternative" defaultValue={item.termsConditionUrl} onChange={(e) => {item.termsConditionUrl = e.target.value}} type="text"/>
                                            </FormGroup>
                                            <Button className="my-4" color="danger" size="sm" type="button"
                                                    onClick={(e) => removeFromList(item, index)}>хасах</Button>
                                        </Row>
                                    </>
                                ))}
                                </div>
                                <Row>
                                    <Button className="my-4"
                                            color={status ? 'success' : 'warning'}
                                            size="sm"
                                            type="checkbox"
                                            onClick={toggleSwitch}>
                                        {status ? 'Идэвхитэй' : 'Идэвхигүй'}
                                    </Button>
                                    <Button className="my-4" color="primary" size="sm" type="button" onClick={saveData}>Хадгалах</Button>
                                </Row>

                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default InvestPackage;
