// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";
import {useNavigate} from 'react-router-dom';

// core components
import Header from "components/Headers/Header.js";
import React, {useState} from "react";
import BffService from "../api";
import {Editor} from "@tinymce/tinymce-react";
import AddUser from "./AddUser";
import addUser from "./AddUser";

const CorporateList = (props) => {
    const [listData, setListData] = useState([]);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [resultAddUser, setResultAddUser] = useState('');

    const handleActionFromComponentB = (message) => {
        setResultAddUser(message);
    };

    React.useEffect(() => {loadCorporates();}, [props.deps]);
    const loadCorporates = async () => {
        let CorporateData = await BffService.GetCorporateList()
        setListData(CorporateData.data.result)
    }
    const showData = async (e) => {
        const propsToPass = {corporateData: e, type: 1};
        navigate('/admin/user-profile', {state: propsToPass});
    }
    const addData = () => {
        const propsToPass = {type: 2};
        navigate('/admin/user-profile', {state: propsToPass});
    }

    const handleClose = () => {
        setIsOpen(false);
    };

    const actionClose = (data) => {
        if (data.status === 'Success') {
            setIsOpen(false)
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} size="lg">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Хэрэглэгч нэмэх</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4"
                                    size="sm"
                                    color="warning"
                                    type="button"
                                    onClick={handleClose}>
                                x
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <AddUser resultAddUser={(message) => actionClose(message)}/>

            </Modal>
            <Header/>
            {/* Page content */}

            <Container className="mt--7" fluid>
                {/* Table */}

                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">


                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Байгууллагийн жагсаалт</h3>
                                    <div>
                                    <Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => addData()}>Бүртгэх</Button>
                                    <Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => setIsOpen(true)}>Хэрэглэгч</Button>
                                    </div>
                                </div>


                            </CardHeader>
                            {listData &&
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Байгууллагын нэр</th>
                                        <th scope="col">Ticker</th>
                                        <th scope="col">Идэвхитэй эсэх</th>
                                        <th scope="col">Түвшин</th>
                                        <th scope="col">Хэмжээ</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {listData.map(element => {
                                        return (
                                            <tr key={element.id}>

                                                <th>{element.corporateName}</th>
                                                <th>{element.tickerName}</th>
                                                <th>{element.isActive}</th>
                                                <th>{element.level}</th>
                                                <th>{element.volume}</th>
                                                <th>
                                                    <Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => showData(element)}>Харах</Button>
                                                </th>
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </Table>
                            }

                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
};

export default CorporateList;
