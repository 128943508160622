// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";
import {useNavigate} from 'react-router-dom';

// core components
import Header from "components/Headers/Header.js";
import React, {useState} from "react";
import BffService from "../api";
import {Editor} from "@tinymce/tinymce-react";
import AddUser from "./AddUser";
import addUser from "./AddUser";
import {now} from "moment";

const Lesson = (props) => {
    const [listData, setListData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [chapter, setChapter] = useState('');
    const [lesson, setLesson] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(true);
    

    React.useEffect(() => {
        fetchLesson()
    }, [props.deps]);
    const saveLesson = async () => {
        let data = {
            Name : name,
            YoutubeLink : youtubeLink,
            Chapter : chapter,
            Lesson : lesson,
            Title : title,
            Description : description,
            Active : active,
        }
        if(id){
            data.ID = id
        }
        let result = await BffService.lessonSaveOrEdit(data)
        setIsOpen(false)
        await fetchLesson()
    }
    const fetchLesson = async () => {
        setLoading(true)
        let zodiacData = await BffService.fetchLesson()
        setListData(zodiacData.data.result.data)
        setFilteredData(zodiacData.data.result.data)
    }
    const toggleSwitch = () => {
        setActive(active !== true);
    };
    const searchData = (e) => {
        let searchText = e.target.value;
        setFilteredData(listData.filter(item => {
            if(
                item.name.toUpperCase().includes(searchText.toUpperCase()) ||
                item.title.toUpperCase().includes(searchText.toUpperCase())
            ){return item}
        }));
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const openModal = (e) => {
        if (e === null) {
            setId(null)
            setName('')
            setYoutubeLink('')
            setChapter(1)
            setLesson(1)
            setTitle('')
            setDescription('')
            setActive(true)
        } else {
            setId(e.id)
            setName(e.name)
            setYoutubeLink(e.youtubeLink)
            setChapter(e.chapter)
            setLesson(e.lesson)
            setTitle(e.title)
            setDescription(e.description)
            setActive(e.active)
        }
        setIsOpen(true);
    };
    return (
        <>
            <Modal isOpen={isOpen} size="lg">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">Capex academy</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                        <Button className="my-4" size="sm" color="warning" type="button" onClick={handleClose}>x</Button>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="cid">name</label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={name}
                                onChange={(e) => setName(e.target.value)}
                                id="cid"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="cid">youtubeLink</label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={youtubeLink}
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                id="cid"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="cid">chapter</label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={chapter}
                                onChange={(e) => setChapter(e.target.value)}
                                id="cid"
                                type="number"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="cid">Lesson</label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={lesson}
                                onChange={(e) => setLesson(e.target.value)}
                                id="cid"
                                type="number"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="cid">Title</label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={title}
                                onChange={(e) => setTitle(e.target.value)}
                                id="cid"
                                type="text"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="12">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="cid">description</label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={description}
                                onChange={(e) => setDescription(e.target.value)}
                                id="cid"
                                type="textarea"
                            />
                        </FormGroup>
                    </Col>


                </Row>
                <Row>
                    <Button className="my-4"
                            color={active ? 'success' : 'warning'}
                            size="sm"
                            type="checkbox"
                            onClick={toggleSwitch}>
                        {active ? 'Идэвхитэй' : 'Идэвхигүй'}
                    </Button>
                    <Button className="my-4"
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={saveLesson}>
                        Хадгалах
                    </Button>
                </Row>
            </CardBody>
        </Modal>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Capex academy</h3>
                                    <div>
                                        <Button color="primary" size="sm" type="button"
                                                onClick={(e) => openModal(null)}>Бүртгэх</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody style={{width: '100%'}}>
                                <Input className="form-control-alternative"  placeholder={"Хайх утга оруулна уу"} defaultValue={''} onKeyUp={(e) => searchData(e)} type="text"/>
                                <br/>
                                <Table className="align-items-center data" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">name</th>
                                        <th scope="col">title</th>
                                        <th scope="col">chapter</th>
                                        <th scope="col">lesson</th>
                                        <th scope="col">description</th>
                                        <th scope="col">active</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredData && filteredData.length > 0 && filteredData.sort((a, b) => a?.lesson - b?.lesson).map(element => {
                                        return (
                                            <tr key={element.id}>
                                                <td style={{whiteSpace: 'normal'}}>{element.name}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.title}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.chapter}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.lesson}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.description}</td>
                                                <td style={{whiteSpace: 'normal', color: element.active ? 'green': "red"}}>{element.active ? 'Идэвхитэй' : 'Идэвхигүй'}</td>
                                                <td><Button className="my-4" color="primary" size="sm"
                                                            type="button"
                                                            onClick={(e) => openModal(element)}>Харах</Button></td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default Lesson;
