import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import React, {useEffect, useState} from "react";
import BffService from "../api";
import {uuid} from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils";
import {v4 as uuidv4} from "uuid";
import { format } from 'date-fns';

const InvestPackageRequests = (props) => {
    const [listData, setListData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [assessment, setAssessment] = useState([]);
    const [note, setNote] = useState([]);
    const [info, setInfo] = useState([]);


    React.useEffect(() => {
        loadRequests()
    }, [props.deps]);
    
    const saveInvestData = async () => {

        try {
            let noteData = {
                Info : note,
                Date: new Date(),
                id: uuidv4()
            }
            let datas = [];
            if(selectedData.invest.info)
                 datas = JSON.parse(selectedData.invest.info)
            datas.push(noteData)
            setInfo(datas)
            let sendRequest = {
                ID: selectedData.invest.id,
                Assesment: assessment,
                Info: JSON.stringify(datas),
            }

             await BffService.InvestAssessment(sendRequest)
            loadRequests()
            setIsOpen(false)
        }catch (e) {
            console.log(e);
        }

    }
    const loadRequests = async () => {

        let result = await BffService.getInvestRequest()
        setListData(result.data.result)
        if(result.data.result.length >0){
            setSelectedData(result.data.result[0])
            setAssessment(result.data.result[0].invest.assessment)
        }

    }
    // const toggleSwitch = () => {
    //     setActive(active !== true);
    // };
    const handleClose = () => {
        setIsOpen(false);
    };
    const openModal = (e) => {
        setSelectedData(e)
        setIsOpen(true);
        if(e.invest.info !== ""){
            setInfo(JSON.parse(e.invest.info))
        }
        setAssessment(e.invest.assessment)
    };

    const handleStarClick = (selectedRating) => {
        // selectedData.invest.assessment = selectedRating
        setAssessment(selectedRating)
    };
    const renderStatus = (data) => {
        let statustext = 'Хүсэлт гаргасан'
        let statusColor = '#dd896f'

        if (data === 'Analyzing') {
            statustext = 'Cудалгаа хийгдэж байна'
            statusColor = '#dd896f'
        }
        if (data === 'Approved') {
            statustext = 'Баталгаажсан'
            statusColor = '#56e155'
        }
        if (data === 'Requested') {
            statustext = 'Хүсэлт гаргасан'
            statusColor = '#dd896f'
        }
        if (data === 'StopRequested') {
            statustext = 'Цуцлах хүсэлт гаргасан'
            statusColor = 'red'
        }
        if (data === 'Stopped') {
            statustext = 'Цуцлагдсан'
            statusColor = '#dc2316'
        }

        return (
            <span style={{
                color: statusColor,
                fontWeight: 600,
                fontSize: '0.9rem',
                textAlign: "center"
            }}>{statustext}</span>
        )
    };

    const DateTimeFormatter = ({ dateString }) => {
        const formattedDate = (dateString) => {
            const parsedDate = new Date(dateString);
            return parsedDate.toLocaleDateString('mn-MN', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            });
        };

        return (
            <div>
                <p style={{fontSize:'0.8rem'}}>{formattedDate(dateString)}</p>
            </div>
        );
    };
    return (
        <>
            <Modal isOpen={isOpen} size="lg">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Үгсийн сан</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4" size="sm" color="warning" type="button"
                                    onClick={handleClose}>x</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {selectedData && selectedData.invest &&
                        <>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{display: "flex", flexDirection: "column", padding: '20px', borderRadius: '5px', border: '1px solid #E2E1EC', boxShadow: '0 0 1px 2px #0c161133',}}>
                                    <table style={{width: "100%"}}>
                                        <tbody>
                                        <tr>
                                            <td style={{fontWeight: 400}}>CID</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.invest.cid}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Нэр</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.customer.name} </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Утас</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.customer.mobile} </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Имэйл</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.customer.email}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Үнэлгээ</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>
                                                <div>
                                                    {[...Array(5)].map((_, index) => (
                                                        <span
                                                            key={index}
                                                            onClick={() => handleStarClick(index + 1)}
                                                            style={{ cursor: 'pointer', color: index < assessment ? 'gold' : 'gray' }}
                                                        >&#9733;</span>
                                                    ))}
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Дүн</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.invest.amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Хугацааны эцэст</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.invest.calcAmount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Хүсэлтийн огноо</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>
                                                <DateTimeFormatter dateString={selectedData.invest.createdDate.toString()} />
                                                </td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Хувь</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.invest.percentage}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Хугацаа</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{selectedData.invest.period}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight: 400}}>Төлөв</td>
                                            <td style={{
                                                fontWeight: 600,
                                                paddingLeft: '20px'
                                            }}>{renderStatus(selectedData.invest.status)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} style={{justifyContent: "center", fontWeight: 600, padding: '10px'}}>Харилцсан тэмдэглэл</td>
                                        </tr>
                                        <tr >
                                            <th>№</th>
                                            <th>Тэмдэглэл</th>
                                            <th>Огноо</th>
                                        </tr>
                                        {info && info.map((element, index) => {
                                            return (

                                                <tr key={element.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{element.Info}</td>
                                                    <td>
                                                        <DateTimeFormatter dateString={element.Date.toString()} />
                                                        </td>
                                                </tr>
                                            )})
                                        }
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">Тэмдэглэл</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row>
                        <Button className="my-4"
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={saveInvestData}>
                            Хадгалах
                        </Button>
                    </Row>
                </CardBody>
            </Modal>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Хөрөнгө оруулалт</h3>

                                </div>
                            </CardHeader>
                            <CardBody style={{width: '100%', fontSize:'0.8rem'}}>

                                <Table className="align-items-center data" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">нэр - утас - email</th>
                                        <th scope="col">Дүн</th>
                                        <th scope="col">Үнэлгээ</th>
                                        {/*<th scope="col">calDate</th>*/}
                                        <th scope="col">Өссөн дүн</th>
                                        <th scope="col">cid</th>
                                        {/*<th scope="col">createdDate</th>*/}
                                        <th scope="col">Хувь</th>
                                        <th scope="col">Хугацаа</th>
                                        {/*<th scope="col">startedDate</th>*/}
                                        <th scope="col">Төлөв</th>
                                        <th scope="col">Өөрчилсөн огноо</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {listData && listData.length > 0 && listData.map((element, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{whiteSpace: 'normal'}}>{element.customer.name} {element.customer.mobile} {element.customer.email}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.invest.amount}{element.invest.asset}</td>
                                                <td style={{whiteSpace: 'normal'}}>
                                                    <div>
                                                        {[...Array(5)].map((_, index) => (
                                                            <span
                                                                key={index}
                                                                style={{ cursor: 'pointer', color: index < element.invest.assessment ? 'gold' : 'gray' }}
                                                            >&#9733;</span>
                                                        ))}
                                                    </div>

                                                </td>
                                                {/*<td style={{whiteSpace: 'normal'}}>{element.invest.calDate.toString()}</td>*/}
                                                <td style={{whiteSpace: 'normal'}}>{element.invest.calcAmount}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.invest.cid}</td>
                                                {/*<td style={{whiteSpace: 'normal'}}>{element.invest.createdDate.toString()}</td>*/}
                                                <td style={{whiteSpace: 'normal'}}>{element.invest.percentage}%</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.invest.period}-сар</td>
                                                {/*<td style={{whiteSpace: 'normal'}}>{element.invest.startedDate.toString()}</td>*/}
                                                <td style={{whiteSpace: 'normal'}}>{renderStatus(element.invest.status)}</td>
                                                <td style={{whiteSpace: 'normal'}}>

                                                    <DateTimeFormatter dateString={element.invest.editedDate.toString()} />
                                                    </td>
                                                {/*<td style={{whiteSpace: 'normal'}}>{element.stopOption}</td>*/}
                                                <td><Button className="my-4" color="primary" size="sm" type="button"
                                                            onClick={(e) => openModal(element)}>Харах</Button></td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default InvestPackageRequests;
