// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";
import {useNavigate} from 'react-router-dom';

// core components
import Header from "components/Headers/Header.js";
import React, {useState} from "react";
import BffService from "../api";
import { format } from 'date-fns';

import {Editor} from "@tinymce/tinymce-react";
import AddUser from "./AddUser";
import addUser from "./AddUser";
import {now} from "moment";

const CardTransaction = (props) => {
    const [listData, setListData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [id, setId] = useState('');
    const [txId, setTxId] = useState('');
    const [txPostedDt, setTxPostedDt] = useState('');
    const [ctAcct, setCtAcct] = useState('');
    const [txAddInf, setTxAddInf] = useState('');
    const [amt, setAmt] = useState('');
    const [cid, setCid] = useState('');

    React.useEffect(() => {
        fetchFailedDeposit()
    }, [props.deps]);
    const saveLesson = async () => {
        let result = await BffService.fixFailedDeposit(id, cid)
        console.log(result);
        setIsOpen(false)
        await fetchFailedDeposit()
    }
    const fetchFailedDeposit = async () => {
        setLoading(true)
        let listData = await BffService.fetchFailedDeposit()
        console.log(listData);
        const sortedData = listData.data.result.slice().sort((a, b) => b.id - a.id);

        setListData(sortedData)
        setFilteredData(sortedData)
    }

    const handleClose = () => {
        setIsOpen(false);
    };
    const openModal = (e) => {
        setId(e.id)
        setTxId(e.txId)
        setTxPostedDt(e.txPostedDt)
        setCtAcct(e.ctAcct)
        setTxAddInf(e.txAddInf)
        setAmt(e.amt)
        setCid(e.cid)
        setIsOpen(true);
    };
    return (
        <>
            <Modal isOpen={isOpen} size="lg">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Алдаатай гүйлгээ</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4" size="sm" color="warning" type="button" onClick={handleClose}>x</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>

                    <span>Гүйлгээний дугаар :{txId}</span><br/>
                    <span>Гүйлгээ хийсэн огноо :{txPostedDt}</span><br/>
                    <span>Дансны нэр :{ctAcct}</span><br/>
                    <span>Гүйлгээний утга :{txAddInf}</span><br/>
                    <span>Дүн : {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'MNT'}).format(amt)} </span><br/>

                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">CID</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={cid}
                                    onChange={(e) => setCid(e.target.value)}
                                    id="cid"
                                    type="number"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Button className="my-4"
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={saveLesson}>
                            Хадгалах
                        </Button>
                    </Row>
                </CardBody>
            </Modal>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Алдаатай гүйлгээ</h3>
                                </div>
                            </CardHeader>
                            <CardBody style={{width: '100%'}}>
                                {/*<Input className="form-control-alternative"  placeholder={"Хайх утга оруулна уу"} defaultValue={''} onKeyUp={(e) => searchData(e)} type="text"/>*/}
                                <br/>
                                <Table className="align-items-center data" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Гүйлгээний дугаар</th>
                                        <th scope="col">Гүйлгээ хийсэн огноо</th>
                                        <th scope="col">Дансны нэр</th>
                                        <th scope="col">Гүйлгээний утга</th>
                                        <th scope="col">Дүн</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredData && filteredData.length > 0 && filteredData.map(element => {
                                        return (
                                            <tr key={element.id}>
                                                <td style={{whiteSpace: 'normal'}}>{element.txId}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.txPostedDt}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.ctAcct}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.txAddInf}</td>
                                                <td style={{whiteSpace: 'normal'}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'MNT'}).format(element.amt)}</td>

                                                <td><Button className="my-4" color="primary" size="sm"
                                                            type="button"
                                                            onClick={(e) => openModal(element)}>Харах</Button></td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default CardTransaction;
