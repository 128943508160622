/*eslint-disable*/
import {useState} from "react";
import {NavLink as NavLinkRRD, Link} from "react-router-dom";
// nodejs library to set properties for components
import {PropTypes} from "prop-types";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";

var ps;

const Sidebar = (props) => {
    const [collapseOpen, setCollapseOpen] = useState();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    // toggles collapse between opened and closed (true/false)
    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };
    // closes the collapse
    const closeCollapse = () => {
        setCollapseOpen(false);
    };
    // creates the links that appear in the left menu / Sidebar
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (routes.name !== 'Login') {
                return (
                    <NavItem key={key}>
                        <NavLink
                            to={prop.layout + prop.path}
                            tag={NavLinkRRD}
                            onClick={closeCollapse}
                        >
                            <i className={prop.icon}/>
                            {prop.name}
                        </NavLink>
                    </NavItem>
                );
            }
        });

    };

    const {bgColor, routes, logo} = props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: "_blank",
        };
    }

    return (
        <Navbar
            className="navbar-vertical fixed-left navbar-light bg-white"
            expand="md"
            id="sidenav-main"
        >
            <Container fluid>
                {/* Toggler */}
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                >
                    <span className="navbar-toggler-icon"/>
                </button>
                {/* Brand */}
                {logo ? (
                    <NavbarBrand className="pt-0" {...navbarBrandProps}>
                        <img
                            alt={logo.imgAlt}
                            className="navbar-brand-img"
                            src={require("../../assets/img/brand/capex-color.png")}
                        />
                    </NavbarBrand>
                ) : null}
                {/* User */}
                <Nav className="align-items-center d-md-none">
                    {/*<UncontrolledDropdown nav>*/}
                    {/*  <DropdownToggle nav className="nav-link-icon">*/}
                    {/*    <i className="ni ni-bell-55" />*/}
                    {/*  </DropdownToggle>*/}
                    {/*  <DropdownMenu*/}
                    {/*    aria-labelledby="navbar-default_dropdown_1"*/}
                    {/*    className="dropdown-menu-arrow"*/}
                    {/*    right*/}
                    {/*  >*/}
                    {/*    <DropdownItem>Action</DropdownItem>*/}
                    {/*    <DropdownItem>Another action</DropdownItem>*/}
                    {/*    <DropdownItem divider />*/}
                    {/*    <DropdownItem>Something else here</DropdownItem>*/}
                    {/*  </DropdownMenu>*/}
                    {/*</UncontrolledDropdown>*/}
                    {/*<UncontrolledDropdown nav>*/}
                    {/*  <DropdownToggle nav>*/}
                    {/*    <Media className="align-items-center">*/}
                    {/*      <span className="avatar avatar-sm rounded-circle">*/}
                    {/*        /!*<img alt="..." src={require("../../assets/img/theme/team-1-800x800.jpg")}/>*!/*/}
                    {/*      </span>*/}
                    {/*    </Media>*/}
                    {/*  </DropdownToggle>*/}
                    {/*  <DropdownMenu className="dropdown-menu-arrow" right>*/}
                    {/*    <DropdownItem className="noti-title" header tag="div">*/}
                    {/*      <h6 className="text-overflow m-0">Welcome!</h6>*/}
                    {/*    </DropdownItem>*/}
                    {/*    <DropdownItem to="/admin/user-profile" tag={Link}>*/}
                    {/*      <i className="ni ni-single-02" />*/}
                    {/*      <span>My profile</span>*/}
                    {/*    </DropdownItem>*/}
                    {/*    <DropdownItem to="/admin/user-profile" tag={Link}>*/}
                    {/*      <i className="ni ni-settings-gear-65" />*/}
                    {/*      <span>Settings</span>*/}
                    {/*    </DropdownItem>*/}
                    {/*    <DropdownItem to="/admin/user-profile" tag={Link}>*/}
                    {/*      <i className="ni ni-calendar-grid-58" />*/}
                    {/*      <span>Activity</span>*/}
                    {/*    </DropdownItem>*/}
                    {/*    <DropdownItem to="/admin/user-profile" tag={Link}>*/}
                    {/*      <i className="ni ni-support-16" />*/}
                    {/*      <span>Support</span>*/}
                    {/*    </DropdownItem>*/}
                    {/*    <DropdownItem divider />*/}
                    {/*    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>*/}
                    {/*      <i className="ni ni-user-run" />*/}
                    {/*      <span>Logout</span>*/}
                    {/*    </DropdownItem>*/}
                    {/*  </DropdownMenu>*/}
                    {/*</UncontrolledDropdown>*/}
                </Nav>
                {/* Collapse */}
                <Collapse navbar isOpen={collapseOpen}>
                    {/* Collapse header */}
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            {logo ? (
                                <Col className="collapse-brand" xs="6">
                                    {logo.innerLink ? (
                                        <Link to={logo.innerLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc}/>
                                        </Link>
                                    ) : (
                                        <a href={logo.outterLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc}/>
                                        </a>
                                    )}
                                </Col>
                            ) : null}
                            <Col className="collapse-close" xs="6">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={toggleCollapse}
                                >
                                    <span/>
                                    <span/>
                                </button>
                            </Col>
                        </Row>
                    </div>
                    {/* Form */}
                    {/*<Form className="mt-4 mb-3 d-md-none">*/}
                    {/*  <InputGroup className="input-group-rounded input-group-merge">*/}
                    {/*    <Input*/}
                    {/*      aria-label="Search"*/}
                    {/*      className="form-control-rounded form-control-prepended"*/}
                    {/*      placeholder="Search"*/}
                    {/*      type="search"*/}
                    {/*    />*/}
                    {/*    <InputGroupAddon addonType="prepend">*/}
                    {/*      <InputGroupText>*/}
                    {/*        <span className="fa fa-search" />*/}
                    {/*      </InputGroupText>*/}
                    {/*    </InputGroupAddon>*/}
                    {/*  </InputGroup>*/}
                    {/*</Form>*/}
                    {/* Navigation */}
                    {/*<Nav navbar>{createLinks(routes)}</Nav>*/}

                    <hr className="my-3"/>
                    <NavLink to='/admin/user-profile' tag={NavLinkRRD} onClick={closeCollapse}>
                        <i className='ni ni-world text-primary'/> Байгууллага
                    </NavLink>
                    <hr className="my-3"/>
                    <NavLink to='/admin/NewsList' tag={NavLinkRRD} onClick={closeCollapse}>
                        <i className='ni ni-collection text-primary'/> Мэдээ
                    </NavLink>
                    <hr className="my-3"/>


                    {localStorage.getItem('corporate_tickerName') === "CPX" &&
                        <>
                            <NavLink to='/admin/corporate' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-shop text-primary'/> Байгууллагын жагсаалт
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/userList' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-shop text-primary'/> Хэрэглэгчийн жагсаалт
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/zodiac' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-collection text-primary'/> Зурхай
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/glossary' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-collection text-primary'/> Үгийн сан
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/lesson' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-collection text-primary'/> Хичээл
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/quiz' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-collection text-primary'/> Quiz
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/invest' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-collection text-primary'/> Хөрөнгө оруулалт
                            </NavLink>
                            <hr className="my-3"/>
                            <NavLink to='/admin/invest/list' tag={NavLinkRRD} onClick={closeCollapse}>
                                <i className='ni ni-collection text-primary'/> Хөрөнгө оруулалтын хүсэлт
                            </NavLink>
                            <hr className="my-3"/>

                            {(localStorage.getItem('user_cid') === "198629" || localStorage.getItem('user_cid') === "349930") &&
                                <>
                                    <NavLink to='/admin/FailedDeposit' tag={NavLinkRRD} onClick={closeCollapse}>
                                        <i className='ni ni-collection text-primary'/> Алдаатай гүйлгээ
                                    </NavLink>
                                  <hr className="my-3"/>
                                    <NavLink to='/admin/settlement' tag={NavLinkRRD} onClick={closeCollapse}>
                                        <i className='ni ni-collection text-primary'/> settlement
                                    </NavLink>
                                  <hr className="my-3"/>
                                    <NavLink to='/admin/transaction' tag={NavLinkRRD} onClick={closeCollapse}>
                                        <i className='ni ni-collection text-primary'/> transaction
                                    </NavLink>
                                </>

                            }
                            {/*<hr className="my-3" />*/}
                        </>
                    }


                    {/* Divider */}

                    {/* Heading */}
                    {/*<h6 className="navbar-heading text-muted">Documentation</h6>*/}
                    {/* Navigation */}
                    {/*<Nav className="mb-md-3" navbar>*/}
                    {/*  <NavItem>*/}
                    {/*    <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">*/}
                    {/*      <i className="ni ni-spaceship" />*/}
                    {/*      Getting started*/}
                    {/*    </NavLink>*/}
                    {/*  </NavItem>*/}
                    {/*  <NavItem>*/}
                    {/*    <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar">*/}
                    {/*      <i className="ni ni-palette" />*/}
                    {/*      Foundation*/}
                    {/*    </NavLink>*/}
                    {/*  </NavItem>*/}
                    {/*  <NavItem>*/}
                    {/*    <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar">*/}
                    {/*      <i className="ni ni-ui-04" />*/}
                    {/*      Components*/}
                    {/*    </NavLink>*/}
                    {/*  </NavItem>*/}
                    {/*</Nav>*/}
                    {/*<Nav className="mb-md-3" navbar>*/}
                    {/*  <NavItem className="active-pro active">*/}
                    {/*    <NavLink href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=adr-admin-sidebar">*/}
                    {/*      <i className="ni ni-spaceship" />*/}
                    {/*      Upgrade to PRO*/}
                    {/*    </NavLink>*/}
                    {/*  </NavItem>*/}
                    {/*</Nav>*/}
                </Collapse>
            </Container>
        </Navbar>
    );
};

Sidebar.defaultProps = {
    routes: [{}],
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default Sidebar;
