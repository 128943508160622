import axios from 'axios'
// export const urlServer = "http://localhost:5001";
// export const urlServer = "https://st-bff.capex.mn";
export const urlServer = "https://bff.capex.mn";
/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: `${urlServer}`
});

client.defaults.headers.post['Accept'] = 'application/json';
client.defaults.headers.post['Content-Type'] = 'application/json';

client.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => {
  if (!error.response) {
    alert('NETWORK ERROR')
  } else {
      const code = error.response.status
      // const response = error.response.data
      // const originalRequest = error.config;

      if (code === 401 && window.location.pathname !== 'auth/login') {
          // code === 401 && !originalRequest._retry
        // originalRequest._retry = true

          // console.log(window.location.pathname);

        //auth.commit('logout');
        window.location.href = "/auth/login";
      }

      return Promise.reject(error)
    }
}

client.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

// /**
//  * Request Wrapper with default success/error actions
//  */
// const request = function(options) {
//   const onSuccess = function(response) {
//     console.debug('Request Successful!', response);
//     return response.data;
//   }
//
//   const onError = function(error) {
//     console.error('Request Failed:', error.config);
//
//     if (error.response) {
//       // Request was made but server responded with something
//       // other than 2xx
//       console.error('Status:',  error.response.status);
//       console.error('Data:',    error.response.data);
//       console.error('Headers:', error.response.headers);
//
//     } else {
//       // Something else happened while setting up the request
//       // triggered the error
//       console.error('Error Message:', error.message);
//     }
//
//     return Promise.reject(error.response || error.message);
//   }
//
//   return client(options)
//     .then(onSuccess)
//     .catch(onError);
// }

export default client;
