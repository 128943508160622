import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Modal,
    Col, CardBody, Form, FormGroup, Input,
} from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { v4 as uuidv4 } from 'uuid';

// core components
import Header from "components/Headers/Header.js";
import React, {useRef, useState} from "react";
import BffService from "../api";
import {urlServer} from "../request";

const NewsList = (props) => {
    const [listData, setListData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const editorRef = useRef();
    React.useEffect(() => {loadNews();}, [props.deps]);
    const [initialValue, setInitialValue] = useState('');
    const [author, setAuthor] = useState('');
    const [title, setTitle] = useState('');
    const [bannerImg, setBannerImg] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [isLive, setIsLive] = useState(true);
    const [isLoadin, setIsLoadin] = useState(false);
    const [newsType, setNewsType] = useState(1);
    const [id, setId] = useState(null);
    const [file, setFile] = useState(null);
    const [fileErrorReport, setFileErrorReport] = useState(null);
    const [base64String, setBase64String] = useState(null);
    const [newsDataCorporateId, setNewsDataCorporateId] = useState(null);
    const [oldBanner, setOldBanner] = useState(null);

    const loadNews = async () => {
        let newsData = await BffService.getAdminNews()
        // let newsData2 = await BffService.getNewsList()
        setListData(newsData.data.news)
    }
    const saveNews = async () => {
        let corpId = localStorage.getItem('corporate_id');
        let corporate_tickerName = localStorage.getItem('corporate_tickerName');
        let sendData = {
            Author: author,
            Title: title,
            Sub_Title: subTitle,
            NewsData: editorRef.current.getContent(),
            Status: isLive,
            TypeList: corporate_tickerName === 'CPX' ? 1 : 5,
            CorporateId: corpId * 1,
        }
        if(id){
            sendData.ID = id
            sendData.Banner_Img = oldBanner
            await BffService.updateNews(sendData)
        }else {
            if(file){
                await saveFilesData();
            }
            sendData.Banner_Img = localStorage.getItem('corporate_tickerName') === 'CPX' ? `https://s3.ap-east-1.amazonaws.com/cdn.capex.mn/News/${file.name}` :
                `https://s3.ap-east-1.amazonaws.com/cdn.capex.mn/assets/corporate-banners/news-banner/${localStorage.getItem('corporate_tickerName')}.png`
            await BffService.saveNews(sendData)
        }
        setIsLoadin(false)
        handleClose();
        loadNews();

    }
    const showData = async (type, data) => {
        setIsOpen(true)
        setFile(null)
        if(type === 1){
            // add news
            setNewsDataCorporateId(localStorage.getItem('user_corporateId'))
            setId(null)
            setAuthor('')
            setTitle('')
            setBannerImg('')
            setSubTitle('')
            setIsLive(false)
        }else {
            setAuthor(data.author)
            setTitle(data.title)
            setBannerImg(data.banner_Img)
            setSubTitle(data.sub_Title)
            editorRef.current = data.newsData;
            setInitialValue(data.newsData)
            setId(data.id)
            setIsLive(data.status === undefined ? false : data.status)
            setNewsDataCorporateId(data.corporateId)
            setOldBanner(data.banner_Img)
        }
    }
    const handleClose = () => {
        setIsOpen(false);
    };
    const toggleSwitch = () => {
        setIsLive(isLive === true? false: true);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            if(file.size >= 5000000){
                setFileErrorReport("Зөвшөөрөгдсөн хэмжээнээс их байна")
                return
            }else {
                setFileErrorReport(null)
            }
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                setBase64String(base64);
            };
            reader.readAsDataURL(file);
        } else {
            setBase64String(null);
        }
    };

    const saveFilesData = async () =>{
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('file', file);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlServer+`/api/news/file`,
            data : data
        };
        axios.request(config)
            .then((response) => {
                handleClose()
                return file
            })
            .catch((error) => {
                return file
            });
    }
    return (
        <>
            <Modal isOpen={isOpen} size="lg">
                <Col>
                    {/*<Card className="bg-secondary shadow">*/}
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Мэдээ</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button className="my-4"
                                        size="sm"
                                        color="primary"
                                        type="button"
                                        onClick={handleClose}>
                                    x
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" >Нийтлэгч</label>
                                            <Input
                                                className="form-control-alternative"
                                                defaultValue={author}
                                                onChange={(e) => setAuthor(e.target.value)}
                                                id="input-username"
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-email">Гарчиг</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-email"
                                                defaultValue={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                                type="textarea"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {localStorage.getItem('corporate_tickerName') === "CPX" && newsDataCorporateId*1 === 1 &&
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" >Banner зураг</label>

                                                <Input
                                                    className="form-control"
                                                    defaultValue={file}
                                                    onChange={handleFileChange}
                                                    id="input-file"
                                                    accept=".png"
                                                    type="file"
                                                />
                                                {fileErrorReport && fileErrorReport !== '' && <span style={{color: '#de6b6b'}}>{fileErrorReport}</span>}
                                                {bannerImg !== '' &&
                                                    <a href={bannerImg}  target={"_blank"}>
                                                        <Button className="my-4"
                                                                color="primary"
                                                                size="sm"
                                                                type="button">
                                                            харах
                                                        </Button>
                                                    </a>}
                                                {/*<Input*/}
                                                {/*    className="form-control-alternative"*/}
                                                {/*    defaultValue={bannerImg}*/}
                                                {/*    onChange={(e) => setBannerImg(e.target.value)}*/}
                                                {/*    id="input-username"*/}
                                                {/*    type="text"*/}
                                                {/*/>*/}
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col lg="6">
                                        <FormGroup>
                                            <label className="form-control-label" htmlFor="input-email">Дэд гарчиг</label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-email"
                                                defaultValue={subTitle}
                                                onChange={(e) => setSubTitle(e.target.value)}
                                                type="textarea"
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>


                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label className="form-control-label" >Мэдээ</label>
                                            <Editor
                                                apiKey="8tc3ijwnmezr8iyhmnrm5u48mpuvro1o0jvi2quntlsdcpt2"
                                                initialValue={initialValue}
                                                onInit={ (evt, editor) => editorRef.current = editor}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </CardBody>


                    <CardFooter>
                        {localStorage.getItem('corporate_tickerName') === "CPX" &&
                            <Button className="my-4"
                                    color={isLive? 'success': 'warning'}
                                    size="sm"
                                    type="checkbox"
                                    onClick={toggleSwitch}>
                                {isLive? 'Идэвхитэй': 'Идэвхигүй'}
                            </Button>
                        }
                        <Button className="my-4"
                                disabled={isLoadin}
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={saveNews}>
                            Хадгалах
                        </Button>
                    </CardFooter>
                    {/*</Card>*/}
                </Col>

            </Modal>

            <Header/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Мэдээний жагсаалт</h3>
                                    <Button color="primary"
                                            type="button"
                                            size="sm"
                                            onClick={(e) => showData(1, null)}>
                                        Мэдээ нийтлэх
                                    </Button>
                                </div>
                            </CardHeader>

                            {listData &&

                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Нийтлэгч</th>
                                        <th scope="col">Гарчиг</th>
                                        <th scope="col">Үүсгэсэн огноо</th>
                                        <th scope="col">Төлөв</th>
                                        {/*<th scope="col">typeList</th>*/}
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {listData.map(element => {
                                        return (
                                            <tr key={element.id}>

                                                <td style={{whiteSpace: 'normal'}}>{element.author}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.title}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.create_Date}</td>
                                                <td>
                                                    <span style={{color: element.status ? 'green': 'red'}}>{element.status ? 'Идэвхитэй': 'Идэвхигүй'}</span>
                                                </td>
                                                <td>
                                                    <Button className="my-4"
                                                            color="primary"
                                                            type="button"
                                                            size="sm"
                                                            onClick={(e) => showData(2, element)}>
                                                        Харах
                                                    </Button>

                                                </td>

                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </Table>
                            }
                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
};

export default NewsList;
