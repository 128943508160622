import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Modal, Row, Table,} from "reactstrap";
import Header from "components/Headers/Header.js";
import React, {useState} from "react";
import BffService from "../api";
import {v4 as uuidv4} from "uuid";
import { format, parseISO } from 'date-fns';

const CryptoJS = require('crypto-js');

const Quiz = (props) => {
    const [listData, setListData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [windData, setWinData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [reward, setReward] = useState('1000CPX');
    const [minute, setMinute] = useState(15);
    const [winnerListCount, setWinnerListCount] = useState(5);
    const [winners, setWinners] = useState(15);
    const [endTime, setEndTime] = useState(15);
    const [quiz, setQuiz] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenWins, setIsOpenWins] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [question, setQuestion] = useState('');
    const [a, setA] = useState('');
    const [b, setB] = useState('');
    const [c, setC] = useState('');
    const [d, setD] = useState('');
    const [correct, setCorrect] = useState('');
    React.useEffect(() => {
        fetchQuiz()
    }, [props.deps]);
    const CryptoJS = require('crypto-js');
    const ivHex = '00112233445566778899AABBCCDDEEFF';
    const iv = CryptoJS.enc.Hex.parse(ivHex);


    const saveQuiz = async () => {
        // for (const item of questionList) {
        //     let aa= await encrypt(item.correct);
        //     item.correct = aa.ciphertext
        // }
        let data = {
            LessonId: 101,
            Name: name,
            Title: title,
            Reward: reward,
            Minute: minute,
            Quiz: JSON.stringify(questionList),
            Description: description,
            Active: active,
            Winners: winners,
            EndTime: endTime
        }
        if (id) {
            data.id = id
        }
        await BffService.quizSaveOrEdit(data)
        setIsOpen(false)
        await fetchQuiz()
    }
    const fetchQuiz = async () => {
        setLoading(true)
        let resultData = await BffService.fetchQuiz()
        setListData(resultData.data.result)
        setFilteredData(resultData.data.result)
    }
    const toggleSwitch = () => {
        setActive(active !== true);
    };
    const searchData = (e) => {
        let searchText = e.target.value;
        setFilteredData(listData.filter(item => {
            if (
                item.description?.toUpperCase().includes(searchText.toUpperCase()) ||
                item.name?.toUpperCase().includes(searchText.toUpperCase()) ||
                item.quiz?.toUpperCase().includes(searchText.toUpperCase()) ||
                item.reward?.toUpperCase().includes(searchText.toUpperCase()) ||
                item.title?.toUpperCase().includes(searchText.toUpperCase())
            ) {
                return item
            }
        }));
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const addListData = async () => {
        let crAnswer = ''
        switch (correct) {
            case 'A':crAnswer = a;break;
            case 'B':crAnswer = b;break;
            case 'C':crAnswer = c;break;
            case 'D':crAnswer = d;break;
            default:crAnswer = b;break;
        }
        let data = {id: uuidv4(), question: question, answer: [a, b, c, d], correct: crAnswer}
        setQuestionList([...questionList, data])
        clearQuestionsForm();
    };
    const clearQuestionsForm = () => {
        setA('')
        setB('')
        setC('')
        setD('')
    }
    const removeFromQuestionList = async (e, index) => {
        clearQuestionsForm();
        const updatedQuestionList = questionList.filter((question) => question.question !== e.question);
        setQuestionList(updatedQuestionList);
    };
    const openWinResult = async (e) => {
        setWinnerListCount(e.winners)
        let resultData = await BffService.fetchWinResult(e.id)
        setIsOpenWins(true)
        setWinData(resultData.data.result)
    }
    const openModal = async (e) => {
        clearQuestionsForm();
        if (e === null) {
            setId(null)
            setName('')
            setTitle('')
            setQuiz('')
            setDescription('')
            setActive(true)
            setReward('1000cpx')
            setMinute(15)
            setWinners(5)

        } else {
            // const qlist = [];
            // JSON.parse(e.quiz).forEach(function (item) {
            //     // item.correct = decrypt(item.correct)
            //     qlist.push(item)
            // })

            setId(e.id)
            setName(e.name)
            setTitle(e.title)
            setQuestionList(JSON.parse(e.quiz))
            setDescription(e.description)
            setActive(e.active)
            setReward(e.reward)
            setMinute(e.minute)
            setWinners(e.winners)


            setEndTime(format(parseISO(e.endTime), "yyyy-MM-dd'T'HH:mm"))
        }
        setIsOpen(true);
    };
    return (
        <>
            <Modal isOpen={isOpenWins} size="xl">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Quiz</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4" size="sm" color="warning" type="button"
                                    onClick={event => setIsOpenWins(false)}
                                    >x</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>

                <Table className="align-items-center data" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">CID</th>
                        <th scope="col">email</th>
                        <th scope="col">Хугацаа</th>
                        <th scope="col">Хариулт</th>
                        <th scope="col">Огноо</th>
                    </tr>
                    </thead>
                    <tbody>
                    {windData && windData.length > 0 && windData.map((element, index) => {
                        return (
                            <tr key={index} style={{backgroundColor: winnerListCount > index ? '#abf3d6': '#fff'}}>
                                <td style={{whiteSpace: 'normal'}}>{element.cid}</td>
                                <td style={{whiteSpace: 'normal'}}>{element.email}</td>
                                <td style={{whiteSpace: 'normal'}}>{element.answertime}</td>
                                <td style={{whiteSpace: 'normal'}}>{element.correctCount}/{element.totalQuestion}</td>
                                <td style={{whiteSpace: 'normal'}}>{element.createdDate}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                </CardBody>
            </Modal>
            <Modal isOpen={isOpen} size="xl">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Quiz</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4" size="sm" color="warning" type="button"
                                    onClick={handleClose}>x</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Нэр</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={name}
                                    onChange={(e) => setName(e.target.value)}
                                    id="cid"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Гарчиг</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    id="cid"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Шагнал</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={reward}
                                    onChange={(e) => setReward(e.target.value)}
                                    id="cid"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Хугацаа</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={minute}
                                    onChange={(e) => setMinute(e.target.value)}
                                    id="cid"
                                    type="number"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Шагналын тоо</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={winners}
                                    onChange={(e) => setWinners(e.target.value)}
                                    id="cid"
                                    type="number"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Дуусах хугацаа</label>

                                <Input
                                    className="form-control-alternative"
                                    defaultValue={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    id="endTime"
                                    type="datetime-local"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="cid">Тухай</label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    id="cid"
                                    type="textarea"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="my-4" color="primary" size="sm" type="button" onClick={event => setCanAdd(true)}>Асуулт
                        бүртгэх</Button>
                    <Row>
                        {canAdd && <Col lg="12">
                            <div>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">Асуулт</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">A</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={a}
                                            onChange={(e) => setA(e.target.value)}
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">B</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={b}
                                            onChange={(e) => setB(e.target.value)}
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">C</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={c}
                                            onChange={(e) => setC(e.target.value)}
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">D</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={d}
                                            onChange={(e) => setD(e.target.value)}
                                            type="textarea"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">Хариулт</label>
                                        <select className="form-control" value={correct}
                                                onChange={(e) => setCorrect(e.target.value)}>
                                            <option value={'A'}>A</option>
                                            <option value={'B'}>B</option>
                                            <option value={'C'}>C</option>
                                            <option value={'D'}>D</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </div>
                            <Button className="my-4" color="primary" size="sm" type="button" onClick={addListData}>Жагсаалтад
                                нэмэх</Button>
                            <Button className="my-4" color="primary" size="sm" type="button"
                                    onClick={event => setCanAdd(false)}>Хаах</Button>
                        </Col>
                        }
                        <Col lg="12">
                            <Table className="align-items-center data" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">Асуулт</th>
                                    <th scope="col">A</th>
                                    <th scope="col">Хариулт</th>
                                    <th scope="col">Үйлдэл</th>
                                </tr>
                                </thead>
                                <tbody>
                                {questionList && questionList.map((element, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{whiteSpace: 'normal'}}>
                                                <Input
                                                    height={200}
                                                    width={400}
                                                    className="form-control-alternative"
                                                    defaultValue={element.question}
                                                    onChange={(e) => {
                                                        element.question = e.target.value
                                                    }}
                                                    type="textarea"
                                                />
                                            </td>

                                            <td style={{whiteSpace: 'normal'}}>
                                                {/*<Input*/}
                                                {/*    className="form-control-alternative"*/}
                                                {/*    defaultValue={element.a}*/}
                                                {/*    onChange={(e) => {element.a = e.target.value}}*/}
                                                {/*    type="textarea"*/}
                                                {/*/>*/}
                                                {/*<td>{element.answer}</td>*/}

                                                {element?.answer?.map((item, index) => {
                                                    return (
                                                        <td>
                                                            <Input
                                                                className="form-control-alternative"
                                                                defaultValue={item}
                                                                onChange={(e) => {
                                                                    item = e.target.value;
                                                                    element.answer[index] = e.target.value
                                                                }}
                                                                type="textarea"
                                                            />
                                                        </td>
                                                    )
                                                })}
                                            </td>
                                            <td>
                                                <Input
                                                    className="form-control-alternative"
                                                    defaultValue={element.correct}
                                                    onChange={(e) => {
                                                        element.correct = e.target.value
                                                    }}
                                                    type="textarea"
                                                />
                                                {/*{element.correct}*/}
                                            </td>
                                            <td>
                                                <Button className="my-4" color="primary" size="sm" type="button"
                                                        onClick={(e) => removeFromQuestionList(element, index)}>хасах</Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Button className="my-4"
                                color={active ? 'success' : 'warning'}
                                size="sm"
                                type="checkbox"
                                onClick={toggleSwitch}>
                            {active ? 'Идэвхитэй' : 'Идэвхигүй'}
                        </Button>
                        <Button className="my-4"
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={saveQuiz}>
                            Хадгалах
                        </Button>
                    </Row>
                </CardBody>
            </Modal>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Quiz</h3>
                                    <div>
                                        <Button color="primary" size="sm" type="button"
                                                onClick={(e) => openModal(null)}>Бүртгэх</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody style={{width: '100%'}}>
                                <Input className="form-control-alternative" placeholder={"Хайх утга оруулна уу"}
                                       defaultValue={''} onKeyUp={(e) => searchData(e)} type="text"/>
                                <br/>
                                <Table className="align-items-center data" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Нэр</th>
                                        <th scope="col">Гарчиг</th>
                                        <th scope="col">Шагнал</th>
                                        <th scope="col">Хугацаа</th>
                                        <th scope="col">Тухай</th>
                                        <th scope="col">Идэвхитэй эсэх</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredData && filteredData.length > 0 && filteredData.map((element, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{whiteSpace: 'normal'}}>{element.name}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.title}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.reward}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.minute}</td>
                                                <td style={{whiteSpace: 'normal'}}>{element.description}</td>
                                                <td style={{
                                                    whiteSpace: 'normal',
                                                    color: element.active ? 'green' : "red"
                                                }}>{element.active ? 'Идэвхитэй' : 'Идэвхигүй'}</td>
                                                <td><Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => openWinResult(element)}>TOP 10</Button>
                                                    <Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => openModal(element)}>Харах</Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default Quiz;
