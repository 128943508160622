import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";
import qs from 'qs';
import async from "async";
import BffService from "../api";
import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';

import {toast, ToastContainer} from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import {urlServer} from "../request";
import { useNavigate } from 'react-router-dom';

const Login = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notification, setNotification] = useState("");
    const [otp, setOtp] = useState("");
    const [textInfo, setTextInfo] = useState("");

    const [isMobileVerify, setIsMobileVerify] = useState("")
    const [tempAuthToken, setTempAuthToken] = useState("")
    const [tokenExpiry, setTokenExpiry] = useState("")
    const [twoFAMehtod, setTwoFAMehtod] = useState("")
    const [hasOtp, setHasOtp] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    React.useEffect(() => {
        // loadProfile();
        toast("check google @FA", {type: 'success'});
    }, [props.deps]);

    if (!localStorage.getItem('deviceId')) {
        const deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceId);
    }

    const loginFunction = async () => {
        setLoading(true)
        let data = {
            email: email,
            password: password
        }
        let getOtpData =await BffService.getOtp(JSON.stringify(data))
        if(getOtpData.status === 'Error'){
            if(getOtpData.message === 'SignIn_Authentication_Failed'){
                setNotification('Нэвтрэх нэр нууц үг буруу байна')
                setLoading(false)
            }
            setLoading(false)
        }else {
            await setHasOtp(true)
            await setIsMobileVerify(getOtpData.data.isMobileVerify)
            await setTempAuthToken(getOtpData.data.tempAuthToken)
            await setTokenExpiry(getOtpData.data.tokenExpiry)
            await setTwoFAMehtod(getOtpData.data.twoFAMehtod)
            setLoading(false)
            if(getOtpData.data.isMobileVerify === true){
                if(getOtpData.data.twoFAMehtod === 'GAuth'){setNotification("Google Authenticator оруулна уу."); return}
                setNotification("Утсанд ирсэн нэг удаагийн код оруулна уу.")
            }else {
                setNotification("Имэйл-р ирсэн нэг удаагийн код оруулна уу")
            }
        }
        setLoading(false)
    }

    const loadProfile = async () => {
        setLoading(true)
        let userData = await BffService.getUserInfo()
        if(userData.data.result === 'data is empty')
        {
            setNotification("Хандах эргүй байна")
        }else {
            if(userData.data.result.corporateInfo === null){
                setNotification("Байгууллагын эрх хүчингүй байна")
                setLoading(false)
            }else {
                setNotification("Нэвтрэх үйлдэл амжилттай")
                localStorage.setItem('corporate_id', userData.data.result.corporateInfo.id);
                localStorage.setItem('corporate_corporateName', userData.data.result.corporateInfo.corporateName);
                localStorage.setItem('corporate_tickerName', userData.data.result.corporateInfo.tickerName);
                localStorage.setItem('corporate_title', userData.data.result.corporateInfo.title);
                localStorage.setItem('corporate_type', userData.data.result.corporateInfo.type);
                localStorage.setItem('corporate_price', userData.data.result.corporateInfo.price);
                localStorage.setItem('corporate_volume', userData.data.result.corporateInfo.volume);
                localStorage.setItem('corporate_first', userData.data.result.corporateInfo.first);
                localStorage.setItem('corporate_web', userData.data.result.corporateInfo.web);
                localStorage.setItem('corporate_whitePaper', userData.data.result.corporateInfo.whitePaper);
                localStorage.setItem('corporate_tickerRule', userData.data.result.corporateInfo.tickerRule);
                localStorage.setItem('corporate_faq', userData.data.result.corporateInfo.faq);
                localStorage.setItem('corporate_level', userData.data.result.corporateInfo.level);
                localStorage.setItem('corporate_isActive', userData.data.result.corporateInfo.isActive);
                localStorage.setItem('corporate_aboutProject', userData.data.result.corporateInfo.aboutProject);
                localStorage.setItem('corporate_features', userData.data.result.corporateInfo.features);
                localStorage.setItem('corporate_socialAccounts', userData.data.result.corporateInfo.socialAccounts);
                localStorage.setItem('user_id', userData.data.result.user.id);
                localStorage.setItem('user_cid', userData.data.result.user.cid);
                localStorage.setItem('user_corporateId', userData.data.result.user.corporateId);
                localStorage.setItem('user_userType', userData.data.result.user.userType);
                localStorage.setItem('user_isActive', userData.data.result.user.isActive);
                localStorage.setItem('user_role', userData.data.result.user.role);
                navigate('/admin/user-profile');
                setLoading(false)
            }
        }
    }
    const LoginReq = async (e) => {
        setLoading(true)
        const data = {
            grant_type: 'password',
            username: tempAuthToken,
            password: otp,
            dvc_otp: '',
        };
        if (localStorage.getItem('deviceId')) {
            data.dvc_id = localStorage.getItem('deviceId')
        }
        try {
            let res = await fetch(
                urlServer+"/token",
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: qs.stringify(data)
                }
            );
            let OTPRes = await res.json();
            if (res.status === 200) {
                localStorage.setItem('token', OTPRes.access_token)
                await loadProfile()
            } else {
                if(OTPRes.error_description === "Exception_Invalid_Token_or_Expired"){
                    setNotification("Хугацаа дууссан!, Нэг удаагийн кодны хүчинтэй хугацаа 120 секунд.")
                    clearForm()
                }else {
                    setNotification("Нэг удаагийн код буруу байна")
                }
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }

    }

    const clearForm = () => {
        setHasOtp(false)
        setOtp("");
        setEmail("");
        setPassword("");
    }
    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">

                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Нэвтрэх хэсэг</small>
                        </div>

                        {notification !== "" &&
                            <div className="text-center mb-4"
                                 style={{borderRadius:'10px', backgroundColor:"rgb(191 200 206)",color: 'black'}}>
                                <small>{notification}</small>
                            </div>
                        }

                        <Form role="form">

                            {!hasOtp &&
                                <div>
                                    <FormGroup className="mb-3">
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Email"
                                                type="email"
                                                autoComplete="new-email"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Password"
                                                type="password"
                                                autoComplete="new-password"
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                            }
                            {hasOtp &&
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            onChange={(e) => setOtp(e.target.value)}
                                            placeholder="Нэг удаагийн код"
                                            type="text"
                                        />
                                    </InputGroup>
                                </FormGroup>
                            }
                            {/*<div className="custom-control custom-control-alternative custom-checkbox">*/}
                            {/*    <input*/}
                            {/*        className="custom-control-input"*/}
                            {/*        id=" customCheckLogin"*/}
                            {/*        type="checkbox"*/}
                            {/*    />*/}
                            {/*    <label*/}
                            {/*        className="custom-control-label"*/}
                            {/*        htmlFor=" customCheckLogin"*/}
                            {/*    >*/}
                            {/*        <span className="text-muted">Сануулах</span>*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            <div className="text-center">

                                {!hasOtp &&
                                <Button className="my-4" color="primary" type="button" onClick={(e) => loginFunction()}>Код авах</Button>
                                }
                                {hasOtp &&
                                    <div>
                                        <Button className="my-4" color="primary" type="button" onClick={(e) => LoginReq()}
                                        disabled={loading}>Нэвтрэх</Button>
                                        <Button className="my-4" color="primary" type="button" onClick={clearForm}
                                                disabled={loading}>Буцах</Button>
                                    </div>
                                }
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default Login;
