import {Row, Button, Col, CardBody, Form, FormGroup, Input, Modal,} from "reactstrap";
import React, {useState} from "react";
import BffService from "../api";
import {useNavigate} from "react-router-dom";

const AddUser = (props) => {
    const [cid, setCid] = useState('');
    const [corp, setCorp] = useState('');
    const [corporateList, setCorporateList] = useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {loadData();}, [props.deps]);
    const loadData = async () => {
        let CorporateData = await BffService.GetCorporateList()
        setCorporateList(CorporateData.data.result)
    }

    const saveUser = async () => {
        let data = await BffService.SetUserCopr(corp, cid)
        props.resultAddUser(data); // Call the callback function
    }
    const handleOptionChange = async (e) => {
        setCorp(e.target.value)
    }
    return (
        <>
            <Col>
                <CardBody>
                    <Form>
                        <div className="pl-lg-4">
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="cid">CID</label>
                                        <Input
                                            className="form-control-alternative"
                                            defaultValue={cid}
                                            onChange={(e) => setCid(e.target.value)}
                                            id="cid"
                                            type="text"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="corp">Байгууллага</label>

                                        <select
                                            className="form-control"
                                            value={corp}
                                            onChange={handleOptionChange}
                                        >
                                            <option value="">Сонгох</option>
                                            {corporateList.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.corporateName}
                                                </option>
                                            ))}
                                        </select>

                                    </FormGroup>
                                </Col>
                            </Row>

                        </div>
                    </Form>
                </CardBody>
                <Button className="my-4"
                        color="primary"
                        size="sm"
                        type="button"
                        onClick={saveUser}>
                    Хэрэглэгч нэмэх
                </Button>
            </Col>
        </>
    );
};

export default AddUser;
