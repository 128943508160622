import request from '../src/request'
import qs from "qs";

function GetCorporateList() {
    return request({
        url: `/api/local/corporate/list`,
        method: 'GET',
    });
}

function GetCorporateDataById() {
    return request({
        url: `/api/local/corporate/byId?id=${localStorage.getItem('user_corporateId')}`,
        method: 'GET',
    });
}

function getOtp(body) {
    return request({
        url: `/api/AuthenticateUser`,
        method: 'POST',
        data: body,
    });
}

function logout() {
    return request({
        url: `/logout`,
        method: 'POST'
    });
}

function loginRequest(body) {
    return request({
        url: `/token`,
        method: 'POST',
        data: body,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
}

function getUserInfo() {
    return request({
        url: `/api/local/GetUserInfo`,
        method: 'GET',
    });
}

function addOrUpdate(body) {
    return request({
        url: `/api/local/corporate/addOrUpdate`,
        method: 'POST',
        data: body
    });
}

function getAdminNews() {
    return request({
        url: `/api/news/getAdminNewsList?id=${localStorage.getItem('corporate_id')}`,
        method: 'GET'
    });
}
function getNewsList() {
    return request({
        url: `/api/news/getNewsList?CID=349930`,
        method: 'GET'
    });
}

function saveNews(body) {
    return request({
        url: `/api/news/saveNews`,
        method: 'POST',
        data: body
    });
}
function SetUserCopr(id, userID) {
    return request({
        url: `/api/local/corporate/SetUserCopr?id=${id}&userID=${userID}`,
        method: 'GET'
    });
}

function updateNews(body) {
    return request({
        url: `/api/news/updateNews`,
        method: 'POST',
        data: body
    });
}
function getUserList(id) {
    return request({
        url: `/api/local/corporate/userList?id=${id}`,
        method: 'GET',
    });
}
function changeUserStatus(id, CID) {
    return request({
        url: `/api/local/corporate/changeUserStatus?id=${id}&userID=${CID}`,
        method: 'GET',
    });
}
function getFiles(id, CID) {
    return request({
        url: `/api/local/corporate/changeUserStatus?id=${id}&userID=${CID}`,
        method: 'GET',
    });
}
function getFile(id) {
    return request({
        url: `/api/local/corporate/getFile?id=${id}`,
        method: 'GET',
    });
}

function changeFileStatus(id) {
    return request({
        url: `/api/local/corporate/changeFileStatus?id=${id}`,
        method: 'GET',
    });
}


function getZodiacNames() {
    return request({
        url: `/api/local/zodiac/getZodiaName`,
        method: 'GET',
    });
}
function getZodiacData(type) {
    return request({
        url: `/api/local/zodiac/getZodiacData?type=${type}`,
        method: 'GET',
    });
}
function zodiacSaveOrEdit(body) {
    return request({
        url: `/api/local/zodiac/SaveOrEdit`,
        method: 'POST',
        data: body
    });
}
function glossarySaveOrEdit(body) {
    return request({
        url: `/api/local/glossary/SaveOrEdit`,
        method: 'POST',
        data: body
    });
}

function getGlossary() {
    return request({
        url: `/api/local/glossary/fetch`,
        method: 'GET',
    });
}
function fetchLesson() {
    return request({
        url: `/api/local/lesson/fetch`,
        method: 'GET',
    });
}
function fetchFailedDeposit() {
    return request({
        url: `api/local/failedDeposit/show`,
        method: 'GET',
    });
}
function fixFailedDeposit(id, CID) {
    return request({
        url: `api/local/failedDeposit/fix?id=${id}&customerId=${CID}`,
        method: 'GET',
    });
}
function lessonSaveOrEdit(body) {
    return request({
        url: `/api/local/lesson/SaveOrEdit`,
        method: 'POST',
        data: body
    });
}
function fetchQuiz() {
    return request({
        url: `/api/local/quiz/fetch?type=99`,
        method: 'GET',
    });
}
function fetchWinResult(id) {
    return request({
        url: `/api/local/quiz/winResult?id=${id}`,
        method: 'GET',
    });
}
function quizSaveOrEdit(body) {
    return request({
        url: `/api/local/quizSaveOrEdit`,
        method: 'POST',
        data: body
    });
}
function getProductData(body) {
    return request({
        url: `/api/investpack/product/list`,
        method: 'GET'
    });
}
function editProductData(body) {
    return request({
        url: `/api/investpack/product/create`,
        method: 'POST',
        data: body
    });
}

function getInvestRequest(body) {
    return request({
        url: `/api/investpack/list?type=0`,
        method: 'GET',
        data: body
    });
}
function InvestAssessment(body) {
    return request({
        url: `/api/investpack/Assess`,
        method: 'POST',
        data: body
    });
}
const BffService = {
    InvestAssessment,
    getInvestRequest,
    editProductData,
    getProductData,
    fetchWinResult,
    fetchQuiz,
    quizSaveOrEdit,
    lessonSaveOrEdit,
    fetchLesson,
    glossarySaveOrEdit,
    getGlossary,
    getZodiacData,
    getZodiacNames,
    zodiacSaveOrEdit,
    changeFileStatus,
    getNewsList,
    getFile,
    SetUserCopr,
    changeUserStatus,
    getUserList,
    GetCorporateDataById,
    GetCorporateList,
    loginRequest,
    getUserInfo,
    addOrUpdate,
    getAdminNews,
    saveNews,
    updateNews,
    getOtp,
    logout,
    fetchFailedDeposit,
    fixFailedDeposit,
}
export default BffService;
