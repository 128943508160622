// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col, Modal, CardFooter, Table,
} from "reactstrap";
// core components
import UserHeader from "../components/Headers/UserHeader.js";
import {useLocation, useNavigate} from 'react-router-dom';
import BffService from "../api";
import React, {useState} from "react";
import async from "async";
import {urlServer} from "../request";
import qs from "qs";

const ProfileCorporate = (props) => {
    const location = useLocation();
    const [corporateData, setCorporateData] = useState({});
    const [id, setid] = useState('')
    const [corporateName, setCorporateName] = useState('')
    const [tickerName, setTickerName] = useState('')
    const [limit, setLimit] = useState('')
    const [title, setTitle] = useState('')
    const [type, setType] = useState('')
    const [price, setPrice] = useState('')
    const [volume, setVolume] = useState('')
    const [first, setFirst] = useState('')
    const [web, setWeb] = useState('')
    const [aboutProject, setAboutProject] = useState('')
    const [features, setFeatures] = useState('')
    const [whitePaper, setWhitePaper] = useState(null)
    const [tickerRule, setTickerRule] = useState('')
    const [faq, setFaq] = useState('')
    const [level, setLevel] = useState('')
    const [file, setFile] = useState('')
    const [fileWp, setFileWp] = useState('')
    const [fileName, setFileName] = useState('')
    const [isActive, setIsActive] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [showFile, setShowFile] = useState(false)
    const [pdfDataUrl, setPdfDataUrl] = useState('');
    const [pdfDatas, setPdfDatas] = useState('');
    const [base64String, setBase64String] = useState(null);
    const [socialAccounts, setSocialAccounts] = useState(null);
    const [fileType, setFileType] = useState(1);
    const [fileError, setFileError] = useState(null);
    const [fileErrorReport, setFileErrorReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileReq, setFileReq] = useState('Хэмжээ 5MB аас бага байх');
    const navigate = useNavigate();
    React.useEffect(() => {
        if (location.state && location.state.type) {
            if (location.state.type === 1) {
                setCorporateData(location.state.corporateData)
                setValue(location.state.corporateData)
                loadFiles(location.state.corporateData.id);
            }
        } else {
            loadCorporates();
        }

    }, [props.deps]);
    const loadCorporates = async () => {
        let data = await BffService.GetCorporateDataById()
        setCorporateData(data.data.result)
        setValue(data.data.result)
        loadFiles(data.data.result.id);
    }
    const loadFiles = async (e) => {
        // if(id){
            let data = await BffService.getFile(e)
            if(data.data.result.length > 0){
                setPdfDatas(data.data.result)
            }
        // }

    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            if(file.size >= 5000000){
                setFileErrorReport("Зөвшөөрөгдсөн хэмжээнээс их байна")
                return
            }else {
                setFileErrorReport(null)
            }
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                setBase64String(base64);
            };
            reader.readAsDataURL(file);
        } else {
            setBase64String(null);
        }
    };
    const handleFileChangeWP = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            // if(file.size >= 1500000){
            //     setFileError("Зөвшөөрөгдсөн хэмжээнээс их байна")
            //     return
            // }else {
            //     setFileError(null)
            // }
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                setWhitePaper(base64);
            };
            reader.readAsDataURL(file);
        } else {
            setWhitePaper(null);
        }
    };
    const setValue = (data) => {
        setid(data.id)
        setCorporateName(data.corporateName)
        setTickerName(data.tickerName)
        setLimit(data.limit)
        setTitle(data.title)
        setType(data.type)
        setPrice(data.price)
        setVolume(data.volume)
        setFirst(data.first)
        setWeb(data.web)
        setWhitePaper(data.whitePaper)
        setTickerRule(data.tickerRule)
        setFaq(data.faq)
        setLevel(data.level)
        setIsActive(data.isActive)
        setAboutProject(data.aboutProject)
        setFeatures(data.features)
        setSocialAccounts(data.socialAccounts)
    }
    const AddFiles = async () => {
        setIsOpen(true)
        setFileName('')
        setFile(null)
        setFileErrorReport(null)
    }
    const saveFile = async () => {
        let sendData = {
            CorporateId: id,
            FileType: fileType,
            FileData: base64String,
            FileName: fileName
        }

        let res = await fetch(
            urlServer+"/api/local/corporate/saveFile",
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization :  localStorage.getItem('token')
                },
                body: qs.stringify(sendData)
            }
        );
        await res.json()
        handleClose()
        loadFiles(sendData.CorporateId)
        setIsOpen(false)
    }

    const saveFilesData = async () =>{
        setLoading(true)
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('file', file);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlServer+`/api/local/file?CorporateId=${corporateData.id}&type=${fileType}&tickerName=${tickerName}&fileName=${fileName}`,
            headers: {
                // ...data.getHeaders()
            },
            data : data
        };
        axios.request(config)
            .then((response) => {
                handleClose()
                loadFiles(corporateData.id)
                setIsOpen(false)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            });
    }
    const saveData = async () => {
        setCorporateName(corporateName === '' ? corporateData.corporateName : corporateName)
        setTickerName(tickerName === '' ? corporateData.tickerName : tickerName)
        setLimit(limit === '' ? corporateData.limit : limit)
        setTitle(title === '' ? corporateData.title : title)
        setType(type === '' ? corporateData.type : type)
        setPrice(price === '' ? corporateData.price : price)
        setVolume(volume === '' ? corporateData.volume : volume)
        setFirst(first === '' ? corporateData.first : first)
        setWeb(web === '' ? corporateData.web : web)
        setAboutProject(aboutProject === '' ? corporateData.aboutProject : aboutProject)
        setFeatures(features === '' ? corporateData.features : features)
        let send_data = {}
        if (location && location.state && location.state.type === 2) {
            send_data = {
                "corporateName": corporateName,
                "tickerName": tickerName,
                "limit": limit,
                "title": title,
                "type": type,
                "price": price * 1,
                "volume": volume * 1,
                "first": first * 1,
                "web": web,
                "aboutProject": aboutProject,
                "features": features,
                "tickerRule": tickerRule,
                "faq": faq,
                "level": level,
                "isActive": 1,
                "whitePaper": `https://s3.ap-east-1.amazonaws.com/cdn.capex.mn/WhitePapers/${tickerName}.pdf`
            }
        } else {
            send_data = {
                "id": id,
                "corporateName": corporateName,
                "tickerName": tickerName,
                "limit": limit,
                "title": title,
                "type": type,
                "price": price * 1,
                "volume": volume * 1,
                "first": first * 1,
                "web": web,
                "aboutProject": aboutProject,
                "features": features,
                "tickerRule": tickerRule,
                "faq": faq,
                "level": level,
                "isActive": 1,
                "whitePaper": `https://s3.ap-east-1.amazonaws.com/cdn.capex.mn/WhitePapers/${tickerName}.pdf`
            }
        }
        if(whitePaper) send_data.whitePaper= whitePaper;
        let data = await BffService.addOrUpdate(send_data)
        if (data.status === 'Success') {
            navigate('/admin/user-profile');
        }
    }
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleCloseFile = () => {
        setShowFile(false);
    };
    const showPdfData = (e) => {
        setShowFile(true)
        setPdfDataUrl(e.fileData)
    };
    const showWPData = () => {
        setShowFile(true)
        setPdfDataUrl(`https://s3.ap-east-1.amazonaws.com/cdn.capex.mn/WhitePapers/${tickerName}.pdf`)
    };

    const fileManage = async (e) => {
        let data = await BffService.changeFileStatus(e.id)
        loadFiles(e.corporateId)
    }
    const handleOptionChange = async (e) => {
        setFileType(e.target.value)
    }
    return (
        <>
            <Modal isOpen={isOpen} size="lg">
                <Card>
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Файл нэмэх</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button className="my-4" color="primary" size="sm" type="button" disabled={loading} onClick={handleClose}>x</Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label">Нэр</label>
                                    <Input className="form-control" placeholder={'Файлын нэр'} defaultValue={fileName} onChange={(e) => setFileName(e.target.value)} id="input-file-name" type="text"/>
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label className="form-control-label">Төрөл</label>
                                    <select className="form-control" value={fileType} onChange={handleOptionChange}>
                                        <option value={1} >WhitePaper</option>
                                        <option value={2} >Үйл ажиллагааны тайлан</option>
                                        <option value={3} >Санхүүгийн тайлан</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-file">Файл
                                        <span style={{color: '#3377c4'}}>{ ' '+fileReq}</span>
                                    </label>

                                    <Input
                                        className="form-control"
                                        defaultValue={file}
                                        onChange={handleFileChange}
                                        id="input-file"
                                        accept=".pdf"
                                        type="file"
                                    />
                                </FormGroup>

                                {fileErrorReport && fileErrorReport !== '' &&
                                    <span style={{color: '#de6b6b'}}>{fileErrorReport}</span>}
                            </Col>

                        </Row>

                    </CardBody>

                </Card>
                <CardFooter>
                    <Col className="text-right" xs="12">
                        <Button color="primary" href="#pablo" disabled={loading} onClick={saveFilesData}
                                size="sm">Хадгалах</Button>

                    </Col>
                </CardFooter>
            </Modal>

            <Modal isOpen={showFile} size="lg">
                <Card>
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Файл харах</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Button className="my-4"
                                        size="sm"
                                        color="primary"
                                        type="button"
                                        onClick={handleCloseFile}>
                                    x
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <iframe title="PDF Viewer" src={pdfDataUrl} width="100%" height="500px"/>
                    </CardBody>
                </Card>
            </Modal>
            <UserHeader tickerName = {tickerName}/>
            {/* Page content */}
            {corporateData &&
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            {/*<h3 className="mb-0">{id}</h3>*/}
                                            <h3 className="mb-0">{corporateName}</h3>
                                        </Col>
                                        {/*<Col className="text-right" xs="4">*/}
                                        {/*    <Button color="primary" href="#pablo" onClick={saveData} size="sm">Хадгалах</Button>*/}
                                        {/*    <Button color="primary" onClick={AddFiles} size="sm">Файл нэмэх</Button>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Байгууллагын нэр
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={corporateName}
                                                            onChange={(e) => setCorporateName(e.target.value)}
                                                            id="input-username"
                                                            placeholder="Username"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Ticker нэр
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            defaultValue={tickerName}
                                                            onChange={(e) => setTickerName(e.target.value)}
                                                            type="text"
                                                            contentEditable={true}
                                                            disabled={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Уриа үг
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            id="input-first-name"
                                                            placeholder="First name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Төрөл
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={type}
                                                            onChange={(e) => setType(e.target.value)}
                                                            id="input-last-name"
                                                            placeholder="Last name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Борлуулах үнэ
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={price}
                                                            onChange={(e) => setPrice(e.target.value)}
                                                            id="input-first-name"
                                                            placeholder="Борлуулах үнэ"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Токен
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={volume}
                                                            onChange={(e) => setVolume(e.target.value)}
                                                            id="input-last-name"
                                                            placeholder="Токен"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Анхны хөрөнгө оруулалт
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={first}
                                                            onChange={(e) => setFirst(e.target.value)}
                                                            id="input-first-name"
                                                            placeholder="First name"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Нэгж хэрэглэгчийн багцын хяцгаар
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={limit}
                                                            onChange={(e) => setLimit(e.target.value)}
                                                            id="input-last-name"
                                                            placeholder="Last name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Вебсайт
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue={web}
                                                            onChange={(e) => setWeb(e.target.value)}
                                                            id="input-first-name"
                                                            placeholder="First name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-wp">
                                                            <span>WhitePaper</span>
                                                            {/*<span style={{color: '#3377c4'}}>{ ' '+fileReq}</span>*/}
                                                        </label>
                                                        {/*<Input*/}
                                                        {/*    className="form-control"*/}
                                                        {/*    defaultValue={whitePaper}*/}
                                                        {/*    onChange={handleFileChangeWP}*/}
                                                        {/*    id="input-wp"*/}
                                                        {/*    accept=".pdf"*/}
                                                        {/*    type="file"*/}
                                                        {/*    placeholder={setFileReq}*/}
                                                        {/*/>*/}

                                                        <Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => showWPData()}>{tickerName} Харах</Button>
                                                    </FormGroup>


                                                    {/*{fileError && fileError !== '' &&*/}
                                                    {/*    <span style={{color: '#de6b6b'}}>{fileError}</span>}*/}

                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="pl-lg-4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-wp">Төслийн танилцуулга</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    rows="5"
                                                    defaultValue={aboutProject}
                                                    onChange={(e) => setAboutProject(e.target.value)}
                                                    type="textarea"
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-wp">Онцлох зүйлс</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    rows="5"
                                                    defaultValue={features}
                                                    onChange={(e) => setFeatures(e.target.value)}
                                                    type="textarea"
                                                />
                                            </FormGroup>
                                        </div>

                                    </Form>
                                </CardBody>
                                <CardFooter>
                                    <Row className="align-items-center">
                                        <Col className="text-right">
                                            <Button color="primary" href="#pablo" onClick={saveData} size="sm">Хадгалах</Button>
                                            <Button color="primary" onClick={AddFiles} size="sm">Файл нэмэх</Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                            {pdfDatas && id &&
                                <div style={{paddingTop: '10px'}}>
                                <Card>
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">Хавсаргасан файл</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>

                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Файлын нэр</th>
                                                <th scope="col">Файлын төрөл</th>
                                                <th scope="col">Идэвхитэй эсэх</th>
                                                <th scope="col">Үйлдэл</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {pdfDatas.map(element => {
                                                return (
                                                    <tr key={element.id}>

                                                        <th>{element.fileName}</th>
                                                        <th>
                                                            <span>{element.fileType === 2 ? 'Үйл ажиллагааны тайлан' : 'Санхүүгийн тайлан'}</span>
                                                        </th>
                                                        <th><span
                                                            style={{color: element.isActive ? 'green' : 'red'}}>{element.isActive ? 'Идэвхитэй' : 'Идэвхигүй'}</span>
                                                        </th>
                                                        <th>
                                                            <Button className="my-4" color="primary" size="sm" type="button"
                                                                    onClick={(e) => showPdfData(element)}>Харах</Button>
                                                            <Button className="my-4" size="sm"
                                                                    color={element.userActive ? 'danger' : 'primary'}
                                                                    type="button"
                                                                    onClick={(e) => fileManage(element)}>{element.isActive ? 'Хаах' : 'Нээх'}</Button>
                                                        </th>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                                </div>
                            }
                        </Col>




                    </Row>
                </Container>
            }
        </>
    );
};
export default ProfileCorporate;


