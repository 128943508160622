// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col, Table, CardFooter, Pagination, PaginationItem, PaginationLink,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import {useLocation, useNavigate} from 'react-router-dom';

import React, {useState} from "react";
import async from "async";
import BffService from "../api";
import Header from "../components/Headers/Header";

const UserCorporate = (props) => {
    const [listData, setListData] = useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        loadCorporates();
    }, [props.deps]);
    const loadCorporates = async () => {
        let CorporateData = await BffService.getUserList(0)

        setListData(CorporateData.data.result.data)
    }

    const userManage = async (e) => {
        e.userActive = !e.userActive
        let CorporateData = await BffService.changeUserStatus(e.corporateId,e.cid)
        loadCorporates();
        // console.log(CorporateData);
    }

    return (
        <>
            <Header/>
            {/* Page content */}

            <Container className="mt--7" fluid>
                {/* Table */}

                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">


                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Хэрэглэгчийн жагсаалт</h3>
                                    {/*<Button*/}
                                    {/*    color="primary"*/}
                                    {/*    type="button"*/}
                                    {/*    onClick={(e) => addData()}>*/}
                                    {/*    Бүртгэх*/}
                                    {/*</Button>*/}
                                </div>


                            </CardHeader>
                            {listData &&
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">CID</th>
                                        {/*<th scope="col">Нэр</th>*/}
                                        <th scope="col">Нэр</th>
                                        <th scope="col">Утас</th>
                                        <th scope="col">е-майл</th>
                                        <th scope="col">Байгууллагын ID</th>
                                        <th scope="col">Байгууллагын нэр</th>
                                        <th scope="col">Тикэр нэр</th>
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {listData.map(element => {
                                        return (
                                            <tr key={element.id}>
                                                <th>{element.cid}</th>
                                                {/*<th>{element.firstName}</th>*/}
                                                <th>{element.name}</th>
                                                <th>{element.mobile}</th>
                                                <th>{element.email}</th>
                                                <th>{element.corporateId}</th>
                                                <th>{element.corporateName}</th>
                                                <th>{element.tickerName}</th>
                                                <th>
                                                    <Button className="my-4" size="sm" color={element.userActive ? 'danger': 'primary'} type="button" onClick={(e) => userManage(element)}>{element.userActive ? 'Хаах': 'Нээх'}</Button>
                                                </th>

                                            </tr>
                                        )
                                    })

                                    }


                                    </tbody>
                                </Table>
                            }
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem className="disabled">
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left"/>
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem className="active">
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                2 <span className="sr-only">(current)</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                3
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-angle-right"/>
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
};

export default UserCorporate;
