// reactstrap components
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip, Button, Col, CardBody, Form, FormGroup, Input, Modal,
} from "reactstrap";
import {useNavigate} from 'react-router-dom';

// core components
import Header from "components/Headers/Header.js";
import React, {useRef, useState} from "react";
import BffService from "../api";
import {Editor} from "@tinymce/tinymce-react";
import AddUser from "./AddUser";
import addUser from "./AddUser";
import {now} from "moment";

const ZodiacList = (props) => {
    const [listData, setListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [zodiacName, setZodiacName] = useState('');
    const [zodiacData, setZodiacData] = useState('');

    const [initialValue, setInitialValue] = useState('');
    const [initialValue2, setInitialValue2] = useState('');
    const [type, setType] = useState(1);
    const [month, setMonth] = useState(1);
    const [zid, setZid] = useState(0);

    const [newId, setNewId] = useState(null);
    const [newType, setNewType] = useState(1);
    const [newMonth, setNewMonth] = useState(1);
    const [newZid, setNewZid] = useState(0);
    const [active, setActive] = useState(true);
    const [dataMn, setDataMn] = useState('');
    const [dataEn, setDataEn] = useState('');

    const editorRefEn = useRef();
    const editorRefMn = useRef();

    React.useEffect(() => {
        loadZName()
        loadZDatas()
    }, [props.deps]);
    const saveZodiac = async () => {

        let data = {
            zType: newType * 1,
            zMonth: newType * 1 === 1 ? newMonth * 1 : 0,
            ZID: newType * 1 === 1 ? 0 : newZid * 1,
            DataMn: editorRefMn.current.getContent(),
            DataEn: editorRefEn.current.getContent(),
            CID: localStorage.getItem('user_cid')
        }
        if(newId !== null || newId !== 0){data.ID = newId}
        console.log(data);
        let result = await BffService.zodiacSaveOrEdit(data)
        setIsOpen(false)
        loadZDatas()
    }

    const loadZName = async () => {
        let zodiacName = await BffService.getZodiacNames()
        setZodiacName(zodiacName.data.result.data)
    }
    const loadZDatas = async () => {
        setLoading(true)
        let zodiacData = await BffService.getZodiacData(type)
        setZodiacData(zodiacData.data.result.data)
        setLoading(false)
    }
    const handleTypeChange = async (e) => {
        setType(e.target.value)
        if(e.target.value === 2){
            setMonth(0)
        }
    }
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleZodiacChange = async (e) => {
        setZid(e.target.value)
    }

    const toggleSwitch = () => {
        setActive(active !== true);
    };
    const  openModal= (e) => {
        if(e === null){
            setNewId(null)
            setDataMn('')
            setDataEn('')
        }else{
            setNewId(e.id)
            setNewType(e.zType)
            setNewMonth(e.zMonth)
            setNewZid(e.zid)
            editorRefMn.current = e.dataMn
            setInitialValue(dataMn)
            editorRefEn.current = e.dataEn
            setInitialValue2(dataEn)
            setDataMn(e.dataMn)
            setDataEn(e.dataEn)
        }
        setIsOpen(true);
    };
    return (
        <>
            <Modal isOpen={isOpen} size="lg">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Зурхайн мэдээлэл</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button className="my-4" size="sm" color="warning" type="button" onClick={handleClose}>x</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg="4">
                            <FormGroup>
                                <label className="form-control-label">Төрөл</label>
                                <select className="form-control" value={newType} onChange={e=>setNewType(e.target.value)}>
                                    <option value={1} >Сар бүрийн зурхай</option>
                                    <option value={2} >Ерөнхий зурхай</option>

                                </select>
                            </FormGroup>
                        </Col>
                        {/*{newType === 2 &&*/}
                            <Col lg="4">

                                <FormGroup>
                                    <label className="form-control-label">Орд</label>
                                    <select className="form-control" value={newZid} onChange={e=>setNewZid(e.target.value)}>
                                        <option value={0} >Сонгох</option>
                                        {zodiacName && zodiacName.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.id} - {option.nameMn}
                                            </option>
                                        ))}

                                    </select>
                                </FormGroup>
                            </Col>
                        {/*}*/}

                        {/*{newType === 1 &&*/}
                            <Col lg="4">
                                <FormGroup>
                                    <label className="form-control-label">Сар</label>
                                    <select className="form-control" value={newMonth}
                                            onChange={e => setNewMonth(e.target.value)}>
                                        <option value={12}>12</option>
                                        <option value={11}>11</option>
                                        <option value={10}>10</option>
                                        <option value={9}>9</option>
                                        <option value={8}>8</option>
                                        <option value={7}>7</option>
                                        <option value={6}>6</option>
                                        <option value={5}>5</option>
                                        <option value={4}>4</option>
                                        <option value={3}>3</option>
                                        <option value={2}>2</option>
                                        <option value={1}>1</option>

                                    </select>
                                </FormGroup>
                            </Col>
                        {/*}*/}
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FormGroup>
                                <label className="form-control-label" >Монгол</label>
                                {/*<Input*/}
                                {/*    className="form-control-alternative"*/}
                                {/*    defaultValue={dataMn}*/}
                                {/*    onChange={(e) => setDataMn(e.target.value)}*/}
                                {/*    type="textarea"*/}
                                {/*/>*/}
                                <Editor
                                    id={'editrMn'}
                                    apiKey="8tc3ijwnmezr8iyhmnrm5u48mpuvro1o0jvi2quntlsdcpt2"
                                    initialValue={initialValue}
                                    currentContent={initialValue}
                                    onInit={ (evt, editorMn) => {editorRefMn.current = editorMn}}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup>
                                <label className="form-control-label" >English</label>
                                {/*<Input*/}
                                {/*    className="form-control-alternative"*/}
                                {/*    defaultValue={dataEn}*/}
                                {/*    onChange={(e) => setDataEn(e.target.value)}*/}
                                {/*    type="textarea"*/}
                                {/*/>*/}
                                <Editor
                                    id={'editrEn'}
                                    apiKey="8tc3ijwnmezr8iyhmnrm5u48mpuvro1o0jvi2quntlsdcpt2"
                                    currentContent={initialValue2}
                                    initialValue={initialValue2}
                                    onInit={ (evt, editorEn) => {editorRefEn.current = editorEn}}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {/*<Button className="my-4"*/}
                        {/*        color={active? 'success': 'warning'}*/}
                        {/*        size="sm"*/}
                        {/*        type="checkbox"*/}
                        {/*        onClick={toggleSwitch}>*/}
                        {/*    {active ? 'Идэвхитэй': 'Идэвхигүй'}*/}
                        {/*</Button>*/}
                        <Button className="my-4"
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={saveZodiac}>
                            Хадгалах
                        </Button>
                    </Row>

                </CardBody>
            </Modal>
            <Header/>
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h3 className="mb-0">Зурхайн мэдээ</h3>
                                    <div>
                                        <Button  color="primary" size="sm" type="button" onClick={(e) => openModal(null)}>Бүртгэх</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="3">
                                        <FormGroup>
                                            <label className="form-control-label">Төрөл</label>
                                            <select className="form-control" value={type} onChange={handleTypeChange}>
                                                <option value={1} >Сар бүрийн зурхай</option>
                                                <option value={2} >Ерөнхий зурхай</option>

                                            </select>
                                        </FormGroup>
                                    </Col>
                                    {/*<Col lg="3">*/}
                                    {/*    <FormGroup>*/}
                                    {/*        <label className="form-control-label">Орд</label>*/}
                                    {/*        <select className="form-control" value={zid} onChange={handleZodiacChange}>*/}
                                    {/*            <option value={0} >Сонгох</option>*/}
                                    {/*            {zodiacName&& zodiacName.map((option) => (*/}
                                    {/*                <option key={option.id} value={option.id}>*/}
                                    {/*                    {option.id} - {option.nameMn}*/}
                                    {/*                </option>*/}
                                    {/*            ))}*/}
                                    {/*        </select>*/}
                                    {/*    </FormGroup>*/}
                                    {/*</Col>*/}
                                    {/*<Col lg="3">*/}
                                    {/*    <FormGroup>*/}
                                    {/*        <label className="form-control-label">Сар</label>*/}
                                    {/*        <select className="form-control" value={month} onChange={e=>setMonth(e.target.value)}>*/}
                                    {/*            <option value={12}>12</option>*/}
                                    {/*            <option value={11}>11</option>*/}
                                    {/*            <option value={10}>10</option>*/}
                                    {/*            <option value={9}>9</option>*/}
                                    {/*            <option value={8}>8</option>*/}
                                    {/*            <option value={7}>7</option>*/}
                                    {/*            <option value={6}>6</option>*/}
                                    {/*            <option value={5}>5</option>*/}
                                    {/*            <option value={4}>4</option>*/}
                                    {/*            <option value={3}>3</option>*/}
                                    {/*            <option value={2}>2</option>*/}
                                    {/*            <option value={1}>1</option>*/}

                                    {/*        </select>*/}
                                    {/*    </FormGroup>*/}
                                    {/*</Col>*/}
                                    <Col lg="3" style={{display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
                                            <Button  color="primary" style={{height: 'fit-content' }} disabled={loading} size="sm" type="button" onClick={loadZDatas}>Хайх</Button>
                                    </Col>
                                </Row>

                            {listData &&
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Төрөл</th>
                                        <th scope="col">Орд</th>
                                        <th scope="col">Сар</th>
                                        {/*<th scope="col">Хэмжээ</th>*/}
                                        <th scope="col">Үйлдэл</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {zodiacData&& zodiacData.sort((a, b) => a?.zMonth - b?.zMonth).map(element => {
                                        return (
                                            <tr key={element.id}>
                                                <th>{element.zType === 1 ?"Сар бүрийн зурхай": "Ерөнхий зурхай"}</th>
                                                <th>{  element.zid === 0 ? 'Бүгд':zodiacName?.find(item => item.id === element.zid).nameMn}</th>
                                                <th>{element.zMonth !== 0 ? element.zMonth : 'Бүгд'}</th>
                                                <th>
                                                    <Button className="my-4" color="primary" size="sm" type="button" onClick={(e) => openModal(element)}>Харах</Button>
                                                </th>
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </Table>
                            }
                            </CardBody>

                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
};

export default ZodiacList;
